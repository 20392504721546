import * as actionTypes from '../../actions/actionTypes';
import _ from 'lodash';

const initialState = {
  employeeList: null,
  sortedList: null,
  employeeData: null,
  count: null,
  csvMake: false,
  csvData: null,
  paginationCount: null,
  userRolesList: [],
  companyDetails: [],
  setUserDetails:null,
  userDevices:[],
  userWellnessInterest:[],
  userProfileReward:[],
  userProfileCompany:[],
  userConnectedDevice:[],
  shoutOutData:[],
  userInitiativesData:[],
  employeeAdminData: null
};

const updateEmployee = (lists, uid, status) => {
  let index = lists.findIndex((list) => list.uid === uid);
  let updationData = [...lists];
  if(index > -1){
    if(status) {
      updationData[index]['is_champion'] = 1;
    }
    else {
      updationData[index]['is_champion'] = 0
    }
  }
  return updationData;
};

const deleteEmployee = (lists, uid) => {
  let index = lists.findIndex((list) => list.uid === uid);
  let updationData = [...lists];
  if(index > -1) {
    updationData[index]['is_user_deleted'] = 1;
  }
  return updationData;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  
  case actionTypes.SET_ADD_EMPLOYEE_SUCCESS:
    return {
      ...state,
      csvMake: true,
      csvData: action.data
    }
  
  case actionTypes.REMOVE_CSV:
    return {
      ...state,
      csvMake: false,
      csvData: null
    }

  case actionTypes.GET_SEARCH_PEOPLE: {
    // let data = [];
    // if(action.pageNumber === 1 || !state.employeeData) {
    //   data = action.data;
    // } else {
    //   data = [
    //     ...state.employeeData,
    //     ...action.data
    //   ]
    // }
    return {
      ...state,
      employeeData: action.data
    }
  }

  case actionTypes.ADD_AND_UPDATE_LOCATION: {
    return {
      ...state,
      employeeData: action.data
    }
  }

  case actionTypes.ADD_AND_UPDATE_DEPARTMENTS: {
    return {
      ...state,
      employeeData: action.data
    }
  }

  case actionTypes.ADD_BULK_USER_FOR_LOCATION: {
    return {
      ...state,
      employeeData: action.data
    }
  }

  case actionTypes.ADD_BULK_USER_FOR_DEPARTMENT: {
    return {
      ...state,
      employeeData: action.data
    }
  }


  case actionTypes.SET_REMOVE_ROLE: {
    const list = updateEmployee(state.employeeAdminData, action.uid, action.status);
    return {
      ...state,
      employeeData: list
    }
  }

  case actionTypes.DELETE_EMPLOYEE: {
    return {
      ...state,
      employeeAdminData: deleteEmployee(state.employeeAdminData, action.uid)
    }
  }

  case actionTypes.GET_EMPLOYEE_LIST:
    return {
      ...state,
      employeeList: action.employeeList
    };

  case actionTypes.SET_EMPLOYEE_COUNT:
    return {
      ...state,
      count: action.count
    }

  case actionTypes.SET_PAGINATION_EMPLOYEE_COUNT:
    return {
      ...state,
      paginationCount: action.count
    }

  case actionTypes.GET_SORTED_EMPLOYEES: {

    const searchedEmployees = state.employeeList.filter((employee) => employee.name.toLowerCase().includes(action.search.toLowerCase()));

    const departmentSort = _.isEmpty(searchedEmployees) ? state.employeeList.filter((employee) => employee.department === action.department) :
      searchedEmployees.filter((employee) => employee.department === action.department);

    const engagementSort = _.isEmpty(departmentSort) ? state.employeeList.filter((employee) =>
      Number(action.engagement.split('-')[0]) < employee.engagement && Number(action.engagement.split('-')[1]) >= employee.engagement) :
      departmentSort.filter((employee) => Number(action.engagement.split('-')[0]) < employee.engagement && Number(action.engagement.split('-')[1]) >= employee.engagement);

    const alphabeticalSort = _.isEmpty(engagementSort) ?
      (action.alphabetical === 'A-Z' ? _.orderBy(state.employeeList, ['name'],['asc'])
        : (action.alphabetical === 'Z-A') && _.orderBy(state.employeeList, ['name'],['desc'])) : (action.alphabetical === 'A-Z' ?
        _.orderBy(engagementSort, ['name'],['asc']) : (action.alphabetical === 'Z-A') && _.orderBy(engagementSort, ['name'],['desc']));

    const list = _.isEmpty(alphabeticalSort) ? (_.isEmpty(engagementSort) ? (_.isEmpty(departmentSort) ? (_.isEmpty(searchedEmployees) ?
      [] : searchedEmployees) : departmentSort) : engagementSort) : alphabeticalSort;


    return {
      ...state,
      sortedList: list
    };
  }
  case actionTypes.SAVE_BIOMETRIC_DETAILS: {
    return {
      ...state,
      biometricDetails: action.biometricDetails
    }
  }
  case actionTypes.SAVE_USER_BIOMETRIC: {
    return {
      ...state,
      biometricInfo: action.biometricInfo
    }
  }
  case actionTypes.USER_ROLES_LIST: {
    return {
      ...state,
      userRolesList: action.userRolesList
    }
  }
  case actionTypes.GET_COMPANY_DETAILS:
    return {
      ...state,
      companyDetails: action.companyDetails
    };
  case actionTypes.SET_USER_DETAILS: 
    return {
      ...state,
      setUserDetails: action.setUserDetails
    }
  case actionTypes.FETCH_USER_CONNECTED_DEVICES: 
    return {
      ...state,
      userDevices: action.userDevices
    }
  case actionTypes.FETCH_USER_INTEREST: 
    return {
      ...state,
      userWellnessInterest: action.userWellnessInterest
    }
  case actionTypes.FETCH_USER_PROFILE_REWARD: 
    return {
      ...state,
      userProfileReward: action.userProfileReward
    }
  case actionTypes.FETCH_USER_COMPANY_DETAILS: 
    return {
      ...state,
      userProfileCompany: action.userProfileCompany
    }
  case actionTypes.FETCH_USER_DEVICES: 
    return {
      ...state,
      userConnectedDevice: action.userConnectedDevice
    }
  case actionTypes.FETCH_USER_SHOUT_OUT: 
    return {
      ...state,
      shoutOutData: action.shoutOutData
    }
  case actionTypes.FETCH_INITIATIVES_DATA: 
    return {
      ...state,
      userInitiativesData: action.userInitiativesData
    }
  case actionTypes.GET_ADMIN_PEOPLE: {
    return {
      ...state,
      employeeAdminData: action.data
    }
  }     
  default:
    break;
  }
  return state;
};

export default reducer;