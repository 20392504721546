/* eslint-disable no-console,no-undef,camelcase,no-case-declarations */
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  companyError: null,
  companyLoggingIn: false,
  companyLoginSuccess: false,
  userData: {'profile':null},
  aoi: [],
  long_term: [],
  signingIn: false,
  signedIn: false,
  error: null,
  exercise_day_per_week: '',
  smoke: '',
  average_sleep: '',
  average_water_intake: '',
  pain_experience: '',
  prescription_intake: '',
  physical_exam_frequency: '',
  wellnessInterests: null,
  interestError: null,
  fitnessGoals: null,
  fitnessError: null,
  companyId: -1,
  companyLocation: -1,
  locationDetails: [],
  departmentDetails: [],
  welcomeBoxValue: false,
  acceptedPrivacyPolicy: 0,
  waitForCompanyDepartment: null,
  waitForCompanyLocation: null,
  showDailyTip: false,
  user_type: 0,
  isSpouseDependent: 0,
  isSpouse: 0,
  isDependent: 0,
  selectedOption: 'employee',
  signupButtonStatus: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  
  case actionTypes.SHOW_CLOSE_WELCOME_BOX:
    return {
      ...state,
      welcomeBoxValue: action.data
    }

  case actionTypes.SET_COMPANY_DEPARTMENT:
    return {
      ...state,
      departmentDetails: action.department
    }

  case actionTypes.SET_COMPANY_LOCATION:
    return {
      ...state,
      locationDetails: action.locations
    }

  case actionTypes.SHOW_DAILY_TIP_POPUP:
    return {
      ...state,
      showDailyTip: true
    }

  case actionTypes.SET_COMPANY_LOCATION_NOT_NULL:
    return {
      ...state,
      waitForCompanyLocation: false
    } 

  case actionTypes.SET_COMPANY_LOCATION_NULL:
    return {
      ...state,
      waitForCompanyLocation: true
    } 

  case actionTypes.SET_COMPANY_DEPARTMENT_NOT_NULL:
    return {
      ...state,
      waitForCompanyDepartment: false
    } 

  case actionTypes.SET_COMPANY_DEPARTMENT_NULL:
    return {
      ...state,
      waitForCompanyDepartment: true
    } 
    
  case actionTypes.COMPANY_LOGIN_ATTEMPT:
    return {
      ...state,
      companyLoggingIn: true,
      companyLoginSuccess: false,
      companyName: action.company_name,
    };

  case actionTypes.COMPANY_LOGIN_FAILED:
    return {
      ...state,
      companyError: action.companyError,
      companyLoggingIn: false,
      companyLoginSuccess: false,
    };

  case actionTypes.COMPANY_LOGIN_SUCCESSFUL:
    return {
      ...state,
      companyError: null,
      companyLoggingIn: false,
      companyLoginSuccess: true,
      userData: {},
      aoi: [],
      long_term: [],
      companyId: -1,
      companyLocation: -1,
      exercise_day_per_week: '',
      smoke: '',
      average_sleep_per_night: '',
      average_water_intake: '',
      pain_experience: '',
      prescription_intake: '',
    };

  case actionTypes.AUTHENTICATION_ERROR:
    return {
      ...state,
      companyError: action.payload,
    };

  case actionTypes.GET_USER_INFORMATION:
    return {
      ...state
    };

  case actionTypes.GET_WELLNESS_INTERESTS:
    return {
      ...state,
      wellnessInterests: action.wellnessInterests
    };

  case actionTypes.FAILED_GET_WELLNESS_INTERESTS:
    return {
      ...state,
      interestError: action.interestError
    };

  case actionTypes.GET_FITNESS_GOALS:
    return {
      ...state,
      fitnessGoals: action.fitnessGoals
    };

  case actionTypes.FAILED_GET_FITNESS_GOALS:
    return {
      ...state,
      fitnessError: action.fitnessError
    };

  case actionTypes.GET_AREA_OF_INTERESTS:
    return {
      ...state
    };

  case actionTypes.ADD_AREA_OF_INTERESTS:
    const aoi = [
      ...state.aoi
    ];
    if(aoi.includes(action.area)) {
      let index = aoi.findIndex((item) => item === action.area);
      if(index > -1) {
        aoi.splice(index, 1);
      }
    } else {
      aoi.push(action.area);
    }
    return {
      ...state,
      aoi: aoi
    };

  case actionTypes.GET_LONG_TERM_GOALS:
    return {
      ...state
    };

  case actionTypes.ADD_LONG_TERM_GOALS:
    const long_term = [
      ...state.long_term
    ];
    if(long_term.includes(action.goal)) {
      let index = long_term.findIndex((item) => item === action.goal);
      if(index > -1) {
        long_term.splice(index, 1);
      }
    } else {
      long_term.push(action.goal);
    }
    return {
      ...state,
      long_term: long_term
    };

  case actionTypes.GET_GENERAL_HEALTH_DATA:
    return {
      ...state,
    };

  case actionTypes.ADD_GENERAL_HEALTH_DATA:
    return {
      ...state,
      [action.name]: action.value
    };
  case actionTypes.CLEAR_GENERAL_HEALTH_DATA:
    return {
      ...state,
      exercise_day_per_week: '',
      smoke: '',
      average_sleep: '',
      average_water_intake: '',
      pain_experience: '',
      prescription_intake: '',
      physical_exam_frequency: ''
    };
  case actionTypes.RESET_LOCATION_DEPARTMENT:
    const updatedUserData =  Object.assign({}, {...state.userData}, {
      location: '',
      department: ''
    });
    return {
      ...state,
      userData: updatedUserData
    };
  case actionTypes.SIGNUP_ATTEMPT:
    return {
      ...state,
      signingIn: true,
      signedIn: false,
    };

  case actionTypes.SIGNUP_FAILED:
    return {
      ...state,
      error: action.error,
      signingIn: false,
      signedIn: false,
    };

  case actionTypes.SIGNUP_SUCCESSFULLY:
    return {
      ...state,
      error: null,
      signingIn: false,
      signedIn: true,
      userData: {},
      aoi: [],
      long_term: [],
      companyId: -1,
      companyLocation: -1,
      exercise_day_per_week: '',
      smoke: '',
      average_sleep_per_night: '',
      average_water_intake: '',
      pain_experience: '',
      prescription_intake: '',
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError,
    };

  case actionTypes.SAVE_USER_INFORMATION:
    if(action.name !== 'acceptedPrivacyPolicy') {
      const userData = {
        ...state.userData,
        [action.name]: action.value
      };
      return {
        ...state,
        userData: userData,
      };
    } else {
      return {
        ...state,
        'acceptedPrivacyPolicy': action.value
      };
    }
  case actionTypes.CLEAR_USER_INFORMATION:
    return {
      userData: null
    };
  case actionTypes.SAVE_COMPANY_ID:
    return {
      ...state,
      companyId: action.companyId
    };

  case actionTypes.GET_COMPANY_ID:
    return {
      ...state,
      companyId: state.companyId
    };
  case actionTypes.FETCH_SPOUSE_DEPENDENT:
    return {
      ...state,
      isSpouseDependent: action.isSpouseDependent
    };
  case actionTypes.FETCH_SPOUSE:
    return {
      ...state,
      isSpouse: action.isSpouse
    };
  case actionTypes.FETCH_DEPENDENT:
    return {
      ...state,
      isDependent: action.isDependent
    };
  case actionTypes.GET_SPOUSE_DEPENDENT_AT_COMPANY_LOGIN:
    return{
      ...state
    };
  case actionTypes.GET_SPOUSE_DEPENDENT_INFO:
    return {
      ...state,
      user_type: state.user_type
    };
  case actionTypes.SET_SPOUSE_DEPENDENT_INFO:
    return {
      ...state,
      user_type: action.user_type,
      selectedOption: action.selectedOption
    };
  case actionTypes.SIGNUP_BUTTON_STATUS:
    return {
      ...state,
      signupButtonStatus: action.bool
    };
  default: break;
  }

  return state;
};

export default reducer;
